require('./bootstrap');
require('./jquery-ui.min');
window.Swal = require('sweetalert2');
require('./bootstrap3-typeahead.min');

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
var URLactual = window.location+'';
if(URLactual==public_html+'admin'||URLactual==public_html+'client'){
    $('.homeButton').addClass('active');
}else{
    $.each($('.nav-link'),function (index,element){
        if(!$(this).hasClass('homeButton')){
            var URLactual = window.location+'';
            var urlParts = URLactual.split(public_html);
            if(Array.isArray(urlParts)){
                if(urlParts.length==2){
                    var urlSubParts = urlParts[1].split('/'),
                        urlNew = [];
                    $.each(urlSubParts,function (ind,el){
                        if(ind==0){
                            urlNew.push(public_html+el);
                        }else{
                            urlNew.push(urlNew[urlNew.length-1]+'/'+el)
                        }
                    });
                    if(urlNew.includes($(this).attr('href'))){
                        $(this).addClass('active');
                        $(this).parent('.nav-item').addClass('menu-open');
                    }
                }
            }
        }
    });
}

$.datepicker.regional['es'] = {
    closeText: 'Cerrar',
    prevText: '< Ant',
    nextText: 'Sig >',
    currentText: 'Hoy',
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
    dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
    weekHeader: 'Sm',
    dateFormat: 'yy-mm-dd',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
};
$.datepicker.setDefaults($.datepicker.regional['es']);

$('.createButton').on('click',function (e){
    Swal.fire({
        icon: 'info',
        title: '',
        html:'Espere un momento por favor...',
        showConfirmButton: false,
        showCancelButton: false,
        showCloseButton: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
    })
});
